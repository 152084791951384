/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import Description from "../components/description";
import FigureGrid from "../components/figureGrid";
import { Banner } from "../components/figures";
import Layout from "../components/layout";
import List from "../components/lists";
import PdfDisplay from "../components/pdfDisplay";
import Section from "../components/section";

import SEO from "../components/seo";

const AboutPage = props => {
    const { data, errors } = props;

    console.log(props);

    if (errors) {
        return <div>Error</div>;
    }

    const page = (data || {}).page;

    console.log(page);

    return (
        <Layout>
            <SEO title="About Me" />
            <Section name="hero" hero>
                <h1 sx={{ pb: 6, variant: "text.title.main" }}>Catchy About title</h1>
                <Banner fullscreen hero imageInfo={page.banner} />
            </Section>
            <Section name="experience">
                <Description>{page.aboutSummary}</Description>
                <Banner imageInfo={page.breakImage} />
                <List
                    list={page._rawExperience.components}
                    title={page._rawExperience.title}
                    description={page._rawExperience.description}
                />
                <FigureGrid list={page._rawExperienceImages} />
            </Section>
            <Section name="education">
                <List
                    list={page._rawEducation.components}
                    title={page._rawEducation.title}
                    description={page._rawEducation.description}
                />
                <Banner fullscreen imageInfo={page.educationbanner} />
            </Section>
            <Section name="passions">
                <Description heading="Passions">{page.passionsSummary}</Description>
                <Banner imageInfo={page.passionsbanner} />
                <div className="passion-list" sx={{ position: "relative" }}>
                    {/* Used SVGS to produce the outlined vertical words, for future reference be careful when modifying */}
                    <svg
                        viewBox="0 0 170 60"
                        sx={{
                            variant: "text.vertical",
                            top: 6,
                            left: ["-160px", "-190px", "-290px", "-325px"],
                            zIndex: 2
                        }}
                    >
                        <text
                            y="55"
                            sx={{
                                fill: "none",
                                stroke: "border",
                                strokeWidth: "1px",
                                strokeLinejoin: "round"
                            }}
                        >
                            RISK
                        </text>
                    </svg>
                    <svg
                        viewBox="0 0 450 60"
                        sx={{
                            variant: "text.vertical",
                            top: ["35%", "34%", "32%"],
                            right: ["-462px", "-540px", "-710px", "-835px"],
                            zIndex: 2
                        }}
                    >
                        <text
                            y="55"
                            sx={{
                                fill: "none",
                                stroke: "border",
                                strokeWidth: "1px",
                                strokeLinejoin: "round"
                            }}
                        >
                            ADVENTURE
                        </text>
                    </svg>
                    <svg
                        viewBox="0 0 500 60"
                        sx={{
                            variant: "text.vertical",
                            bottom: ["15%", "20%", "30%"],
                            left: ["-520px", "-605px", "-785px", "-925px"],
                            zIndex: 2
                        }}
                    >
                        <text
                            y="55"
                            sx={{
                                fill: "none",
                                stroke: "border",
                                strokeWidth: "1px",
                                strokeLinejoin: "round"
                            }}
                        >
                            COMPETITION
                        </text>
                    </svg>
                    <FigureGrid list={page._rawPassions} />
                </div>
            </Section>
            <Section name="resume">
                <h1 sx={{ variant: "text.title.main" }}>Resume</h1>
                <PdfDisplay pdf={page.resume.asset.url} />
            </Section>
        </Layout>
    );
};

export const query = graphql`
    query AboutPageQuery {
        page: sanityAboutPage(_id: { regex: "/(drafts.|)aboutPage/" }) {
            title
            banner {
                ...ImageWithPreview
            }
            aboutSummary
            breakImage {
                ...ImageWithPreview
            }
            _rawExperience
            _rawExperienceImages
            _rawEducation
            educationbanner {
                ...ImageWithPreview
            }
            passionsSummary
            passionsbanner {
                ...ImageWithPreview
            }
            _rawPassions
            resume {
                asset {
                    url
                }
            }
        }
    }
`;

export default AboutPage;
